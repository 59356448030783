import {  root  } from './root';

export class RequestAnimationFrameDefinition {
  cancelAnimationFrame: (handle: number) => void;
  requestAnimationFrame: (cb: () => void) => number;
  constructor(root: any) {
    if (root.requestAnimationFrame) {
      this.cancelAnimationFrame = root.cancelAnimationFrame.bind(root);
      this.requestAnimationFrame = root.requestAnimationFrame.bind(root);
    } else if (root.mozRequestAnimationFrame) {
      this.cancelAnimationFrame = root.mozCancelAnimationFrame.bind(root);
      this.requestAnimationFrame = root.mozRequestAnimationFrame.bind(root);
    } else if (root.webkitRequestAnimationFrame) {
      this.cancelAnimationFrame = root.webkitCancelAnimationFrame.bind(root);
      this.requestAnimationFrame = root.webkitRequestAnimationFrame.bind(root);
    } else if (root.msRequestAnimationFrame) {
      this.cancelAnimationFrame = root.msCancelAnimationFrame.bind(root);
      this.requestAnimationFrame = root.msRequestAnimationFrame.bind(root);
    } else if (root.oRequestAnimationFrame) {
      this.cancelAnimationFrame = root.oCancelAnimationFrame.bind(root);
      this.requestAnimationFrame = root.oRequestAnimationFrame.bind(root);
    } else {
      this.cancelAnimationFrame = root.clearTimeout.bind(root);
      this.requestAnimationFrame = function(cb) { return root.setTimeout(cb, 1000 / 60); };
    }
  }
}

export const AnimationFrame = new RequestAnimationFrameDefinition(root);
