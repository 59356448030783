import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ng5-slider-tooltip-wrapper',
  template: `<ng-container *ngIf="template">
  <ng-template *ngTemplateOutlet="template; context: {tooltip: tooltip, placement: placement, content: content}"></ng-template>
</ng-container>

<ng-container *ngIf="!template">
  <div class="ng5-slider-inner-tooltip" [attr.title]="tooltip" [attr.data-tooltip-placement]="placement">
    {{content}}
  </div>
</ng-container>`,
  styles: [`.ng5-slider-inner-tooltip{height:100%}`]
})
export class TooltipWrapperComponent {
  @Input()
  template: TemplateRef<any>;

  @Input()
  tooltip: string;

  @Input()
  placement: string;

  @Input()
  content: string;
}
